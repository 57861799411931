@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: contain;
  display: inline-block;
}

.icon-close {
	.iconGen(close, 42px, 42px);
}
.icon-file {
  .iconGen(file-icon, 20px, 23px);
}
.icon-search {
  .iconGen(search, 20px, 20px);
}