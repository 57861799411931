// @import "./antd.less";
@import "./var";
@import "./common";
@import "./icon";
@import "../../../commonStyle/reset";
@import "./animation";
@import "./xh";
@import "../../../commonStyle/common";


.my_reset {
  ol, ul, dl {
    margin-top: 0;
    margin-bottom: 0;
  }
}


html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  //line-height:0;
  //overflow: auto;
}


body, html {
  height: 100%;
  min-height: 100%;
  min-width: 1180px;
}

body {
  padding-top: 88px;
}


#header {
  z-index: 100;
  box-shadow: 0 2px 12px 0 rgba(9, 9, 9, 0.12);
  background-color: @white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 88px;


   .header-right {
    //padding: 10px 0;
    // min-width: 1200px;
    // max-width: 1400px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    justify-content: space-between;
    //justify-content: center;
    //border: solid 1px red;

     .logo {
      //height: 100%;
      flex-shrink: 0;
      // margin-right: 235px;
      .flex-center;
      //border: solid 1px red;
      width: 205px;
      height: 62px;

    }

    .right {
      height: 100%;
      .flex;
      align-items: center;
    }

     ul {
      color: @green;
      display: flex;
      flex-shrink: 0;
      font-size: 16px;
      margin-right: 50px;
      height: 100%;
      justify-content: center;
      //border: solid 1px red;

      > li {
        //border: solid 1px;
        margin-right: 12px;
        .flex-center;
        position: relative;
        height: 100%;
        color: #1A7E53;

        > a {
          color: #1A7E53;
          text-decoration: none;
          cursor: pointer;
        }

        a:hover {
          color: #1A7E53;
        }

        a:link, a:visited, a:active {
          color: #1A7E53;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          > .header-title-index {
            background: @dark-green;
            color: #FFFFFF;
            flex-shrink: 0;
            transition: all 0.3s;

            a {
              color: #FFFFFF;
            }
          }
        }

        &.now {
          > .header-title-index {
            background: @dark-green;
            color: #FFFFFF;
            flex-shrink: 0;
          }
        }


        .header-title-index {
          border-radius: 14px;
          height: 31px;
          .flex-center;

          > a {
            padding: 7px 13px 8px 13px;
          }
        }

        .sub-menu {
          box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
          background: white;
          position: absolute;
          top: 88px;
          color: black;
          display: none;
          left: 0;
          font-size: 14px;

          > a {
            white-space: nowrap;
            text-align: center;
            display: block;
            padding: 15px;
            color: black;
            background: white;
            width: auto;
            &.active {
              color: @dark-green;
            }

            &.now {
              color: @dark-green;
            }
          }


        }


      }


    }

     .last-seek {
      //border: solid 1px red;
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      //margin-left: 15px;
      //margin-right: 15px;

      > a {
        .flex-center;
        width: 100%;
        height: 100%;

        > img {
          width: 21px;
          height: 21px;
        }
      }

    }

  }
}

// @media screen and  (max-width: 1400px) {
//   #header {
//     .header-right {
//       > .logo {
//         //border: solid 1px;
//         margin-right: 119px;
//       }

//       > ul {
//         //border: solid 1px;
//         margin-right: 31px;

//         > li {
//           //border: solid 1px;
//           margin-right: 12px;
//         }
//       }
//     }
//   }
// }

// @media screen and  (max-width: 1300px) {
//   #header {
//     .header-right {
//       > .logo {
//         margin-right: 31px;
//       }

//       > ul {
//         margin-left: 15px;

//         > li {
//           margin-right: 12px;
//         }
//       }
//     }
//   }
// }


.page-index {
  .index-swiper {
    //border: solid 1px red;

    > .swiper-wrapper .swiper-slide .img {
      //height: 700px;
      //width: 100%;
    }

    > .swiper-pagination {
      width: 50px;
      height: auto;
      left: 96%;
      transform: translateY(-50%);
      top: 50%;
      bottom: auto; //覆盖他人的样式
    }

    .swiper-pagination-bullet {
      line-height: 20px;
      background: #FFFFFF;
      opacity: 1;
      font-size: 0;
      width: 8px;
      height: 8px;
      margin: 10px;
    }

    .swiper-pagination-bullet-active {
      width: 20px;
      height: 20px;
      background: none;
      color: #FFFFFF;
      font-size: 10px;
    }
  }
}

.index-content {
  //border: solid 1px red;

  .container {

    width: 1200px;
    margin: 0 auto;
  }
}


.project {
  width: 1200px;
  margin-top: 74px;
  //border: solid 1px red;

  .project-text {
    //border: solid 1px red;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .project-01 {
      margin-left: 122px;
      width: 49px;
      height: 47px;
    }

    .project-02 {
      font-size: 26px;
      color: #333333;
    }

    .project-03 {
      font-size: 16px;
      color: @gray;
    }
  }

  .project-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border: solid 1px red;

    .project-title {
      //border: solid 1px red;
      height: 462px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;


      > li {
        cursor: pointer;
        color: @light-black;
        font-size: 18px;
        margin-bottom: 50px;
        position: relative;
        line-height: 1;
        //border: solid 1px red;
        &:nth-child(1) {
          margin-top: 31px;
          //border: solid 1px red;
        }


        > span {
          max-width: 100px;
          .text-cut;
          display: inline-block;
          //border: solid 1px red;
        }

        &.active {
          color: @green;
          //position: relative;
        }

        &.active:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 3px;
          background: @green;
          //transform: translate(-50%, -50%);
          transform: translateY(-50%);
          right: -33px;
          top: 50%;
        }
      }
    }
  }


}


.project-swiper {
  position: relative;
  width: 1048px;
  //border: solid 1px red;
  height: 460px;
  margin: 0;

  .swiper-wrapper {
    //width: 100%;
    width: 1048px;

    .swiper-slide {
      //border: solid 1px red;
      width: 100%;

      .swiper-item {
        width: 100%;
      }

    }
  }


  > .swiper-wrapper .swiper-slide .swiper-item .img {
    width: 640px;
    height: 420px;
  }

  .swiper-item-introduce {
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    z-index: 10;
    position: absolute;
    top: 79px;
    left: 520px;
    width: 524px;
    display: flex;
    flex-direction: column;
    //margin-right: 3px;


    .introduce-title {
      margin-top: 54px;
      margin-left: 43px;
      //margin-bottom: 9px;
      margin-bottom: -13px;
      //border: solid 1px red;

      > div {
        font-size: 24px;
        color: #333333;
      }
    }

    .introduce-text {
      background: url('../../../../img/default/bg-index-main.png') no-repeat center;
      background-size: 524px 219px;
      width: 524px;
      height: 219px;
      display: flex;
      //border: solid 1px red;
      justify-content: center;
      align-items: center;
      margin-bottom: 74px;
      position: relative;

      > .text-content {
        //border: solid 1px;
        width: 439px;
        height: 135px;
        font-size: 16px;
        text-align: justify;
        line-height: 36px;
        margin-top: -11px;
      }

      .introduce-btn {
        margin-left: 42px;
        position: absolute;
        bottom: -23px;
        left: 0;

        > a {
          width: 146px;
          height: 46px;
          color: #FFFFFF;
          background: @green;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }


  }

  > .project-button-prev {
    background: @dark-green;
    background-size: 0;
    color: #FFFFFF;
    width: 50px;
    height: 46px;
    left: 0;
    top: 86.1%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  > .project-button-next {
    background: @dark-green;
    background-size: 0;
    color: #FFFFFF;
    width: 50px;
    height: 46px;
    left: 50px;
    top: 86.1%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon-arrow-right {
  transform: rotateY(150deg);
}


.news {
  //border: solid 1px red;
  margin-top: 119px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;


  > .news-title {
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    line-height: 1.2;
    position: relative;

    > .news-text-img {
      position: absolute;
      width: 49px;
      height: 47px;
      right: -66px;
      bottom: 30px;
    }
  }

  > .news-english {
    margin-top: 18px;
    font-size: 16px;
    font-weight: 400;
    color: @gray;
    line-height: 1;
    margin-bottom: 41px;
  }

  > .news-main {
    //margin-top: 41px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;


    > .news-main-package {

      padding: 30px 0;


      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 520px;
      transition: box-shadow 0.3s;
      position: relative;

      &:hover {
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
        position: relative;

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s;
      }


      &:nth-child(1) {
        @color: #64C2C8;

        &:after {
          background-color: @color;
        }

        .news-main-centre-content {
          .news-main-calendar {
            background-color: @color;
          }
        }
      }

      &:nth-child(2) {
        @color: #AFCA0B;

        &:after {
          background-color: @color;
        }

        .news-main-centre-content {
          .news-main-calendar {
            background: @color;
          }
        }
      }

      &:nth-child(3) {
        @color: #F7A941;

        &:after {
          background-color: @color;
        }

        .news-main-centre-content {
          .news-main-calendar {
            background: @color;
          }
        }
      }

      > a {
        height: 100%;
        > .news-main-centre-content {
          display: flex;
          flex-direction: column;
          height: 100%;

          > div {
            width: 320px;
          }

          > .news-main-calendar {
            // margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 64px;


            > .news-main-calendar-month {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0;
              color: #ffffff;
              line-height: 1.5;
            }

            > .news-main-calendar-day {
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: 0;
              color: #ffffff;
            }
          }

          > .news-main-title {
            height: 56px;
            margin-top: 27px;
            font-size: 18px;
            font-stretch: normal;
            line-height: 1.6;
            font-weight: bold;
            letter-spacing: 0;
            color: #666666;
          }

          > .news-main-text {
            height: 42px;
            margin-top: 24px;
            font-size: 14px;
            font-weight: 400;
            font-stretch: normal;
            //line-height: 24px;
            line-height: 1.5;
            letter-spacing: 0;
            color: #999999;
            width: 320px;
            height: 42px;

          }

          > .news-main-img {
            margin-top: 25px;
            flex-grow: 1;
            width: 320px;
            .img-wrap(320px, 208px);
            > img {
              min-width: 100%;
              min-height: 100%;
            }
          }
        }
      }

    }
  }

}


.index-family {
  margin-top: 122px;
  background: url('../../../../img/default/family-bg.png') no-repeat center;
  background-size: cover;
  padding-top: 53px;
  padding-bottom: 103px;

  > .container {
    width: 1200px;
    margin: 0 auto;

    > .family-text {
      width: 171px;
      height: 104px;
      margin-bottom: 40px;

      > .family-text-log {
        margin-left: 122px;
        width: 49px;
        height: 47px;

      }

      > .family-text-01 {
        > .text-title {
          width: 104px;
          height: 26px;
          font-size: 26px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: 0;
          color: #333333;
        }

        > .text-english {
          margin-top: 17px;
          width: 92px;
          height: 17px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: 0;
          color: @gray;
        }
      }
    }

    > .family-main-package {
      display: flex;
      justify-content: center;

      > ul {
        display: flex;
        //border: solid 1px red;


        > .family-main-package-01, .family-main-package-02 {
          display: flex;
          border-right: solid 1px #FFFFFF;

          > a {
            display: block;
            position: relative;
            width: 350px;
            height: 500px;
            overflow: hidden;

            > .img {
              width: 350px;
              height: 500px;
              transition: all 0.3s;

            }

            &:hover {
              > .img {
                transform: scale(1.05);
              }
            }

            .family-text-show {
              &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                //transform: scale(1.05);

                > div {
                  &:nth-child(3) {
                    opacity: 1;
                  }
                }


              }

              position: absolute;
              left: 0;
              right: 0;
              height: 500px;
              z-index: 10;
              background-color: rgba(0, 0, 0, 0.6);
              transition: all 0.3s;


              > div {
                margin-left: 28px;
                margin-right: 28px;

                &:nth-child(1) {
                  margin-top: 39px;
                  width: 35px;
                  height: 6px;
                  background: #FFFFFF;
                }

                &:nth-child(2) {
                  margin-top: 31px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #FFFFFF;

                }

                &:nth-child(3) {
                  opacity: 0;
                  line-height: 36px;
                  margin-top: 29px;
                  border: solid 1px #ffffff;
                  width: 98px;
                  height: 36px;
                  font-size: 16px;
                  color: #FFFFFF;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: all 0.3s;

                }
              }
            }
          }
        }

        > .family-main-package-03, .family-main-package-04 {
          //border: solid 1px red;
          display: flex;
          flex-direction: column;


          > a {
            display: block;
            width: 250px;
            height: 250px;
            position: relative;
            overflow: hidden;

            > .img {
              width: 250px;
              height: 250px;
              transition: all 0.3s;
              //border: solid 1px #FFFFFF;
            }

            &:hover {
              > .img {
                transform: scale(1.05);
              }
            }


            > .family-text-show {
              &:hover {
                background-color: rgba(0, 0, 0, 0.3);

                div {
                  &:nth-child(3) {
                    opacity: 1;
                  }
                }
              }

              height: 100%;
              position: absolute;
              left: 0;
              right: 0;
              z-index: 10;
              background-color: rgba(0, 0, 0, 0.6);
              transition: all 0.3s;

              > div {
                margin-left: 25px;
                margin-right: 25px;

                &:nth-child(1) {
                  margin-top: 39px;
                  width: 35px;
                  height: 6px;
                  background: #FFFFFF;
                }

                &:nth-child(2) {
                  margin-top: 31px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #FFFFFF;

                }

                &:nth-child(3) {
                  opacity: 0;
                  line-height: 36px;
                  margin-top: 29px;
                  border: solid 1px #ffffff;
                  width: 98px;
                  height: 36px;
                  font-size: 16px;
                  color: #FFFFFF;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: all 0.3s;
                }
              }
            }
          }
        }

        .family-main-package-03 {
          border-right: solid 1px #FFFFFF;

          .family-yangai-log {
            border-top: solid 1px #FFFFFF;
            background: #64C2C8;
            padding: 35px 33px 34px 34px;
            width: 250px;
            height: 250px;
            //height: 181px;
            > .img {
              //border: solid 1px red;
              width: 184px;
              height: 181px;
            }
          }
        }

        .family-main-package-04 {
          > .family-yangai-text {
            border-bottom: solid 1px #FFFFFF;
          }


          > div {
            background: @dark-green;
            width: 250px;
            height: 250px;

            > a {
              overflow: hidden;
              transition: all 0.3s;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              font-size: 24px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 48px
            }


          }
        }

      }


    }
  }
}


.index-resource {
  > .container {
    .container-var;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    > .resource-text-box {
      margin-top: 120px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 40px;

      > .resource-text-log {
        position: absolute;
        right: -50px;
        top: -50px;

        > img {
          width: 49px;
          height: 47px;
        }

      }

      > .text-title {
        .text-centre-text-title
      }

      > .text-english {
        .text-centre-text-english;
        margin-top: 15px;
      }
    }


    > .resource-navigation {
      height: 34px;

      > .resource-navigation-title {
        //border: solid 1px red;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        color: @light-black;
        position: relative;

        > li {

          a:link, a:visited, a:active, a:hover {
            color: @light-black;
          }

          &:hover {
            cursor: pointer;
          }

          &.active {
            color: @green;
            position: relative;

            a:link, a:visited, a:active, a:hover {
              color: @green;
            }

          }

          &.active:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 3px;
            background: @green;
            left: 0;
            right: 0;
            bottom: -14px;
            margin: auto
          }

          margin-left: 161px;

          &:first-child {
            margin-left: 0;
          }

          &:nth-child(2) {
            //margin-left: 161px;
          }

          &:nth-child(3) {
            //margin-left: 161px;
          }


        }


      }


    }


    > .resource-content {
      margin-top: 21px;
      //border: solid 1px red;

      > .resource-content-swiper {
        width: 1204px;
        //border: solid 1px;


        > .swiper-wrapper {
          > .swiper-slide {
            > .resource-swiper-item {
              width: 1204px;
              display: flex;
              flex-wrap: wrap;
              //border: solid 1px;
              justify-content: space-between;

              .swiper-item-left {
                display: flex;
                align-items: center;
                //border: solid 1px red;
                width: 574px;
                height: 72px;

                > .swiper-item-content {
                  width: 100%;
                  height: 100%;

                  > a {
                    width: 100%;
                    height: 100%;
                    //border: solid 1px red;
                    .swiper-item-content;
                    div {
                      flex-grow: 1;
                      padding-right: 20px;
                      .swiper-item-icon {
                        flex-shrink: 0;
                      }
                      .swiper-item-text {
                        flex-grow: 1;
                      }
                    }
                    span {
                      flex-shrink: 0;
                    }
                  }


                }
              }
            }
          }
        }
      }

    }

    > .resource-btn {
      margin-top: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 146px;
      height: 46px;
      background: @green;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.2;
      margin-bottom: 120px;
    }

  }
}

.index-donation {
  background: url('../../../../img/default/contribute-bg.png') no-repeat center;
  height: 580px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  > .donation-text-box {
    width: 350px;
    height: 380px;
    background: #F7A941;
    display: flex;
    flex-direction: column;
    align-items: center;


    > .donation-text-title {
      position: relative;
      width: 250px;
      font-size: 42px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1;
      margin-top: 65px;

      > .box-img {
        //border: solid 1px red;
        width: 49px;
        height: 47px;
        position: absolute;
        top: -41px;
        right: 20px;

        > img {
          //border: solid 1px;
          width: 49px;
          height: 47px;
        }

      }
    }

    > .donation-text-english {
      width: 250px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
    }


    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      height: 60px;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2;
    }

    > .donation-btn-support {
      margin-top: 44px;
      background: #FFFFFF;
      color: #F7A941;
    }

    > .donation-btn-know {
      margin-top: 26px;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
    }


  }

   .dowebok {
     position: relative;
    width: 850px;
    height: 380px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;

    &::before {
      top: 0;
      left: 0;
      position: absolute;
      content: '';
      display: block;
      height: 42px;
      background-color: #fff;
      width: 850px;
      z-index: 6;
    }
    &::after {
      bottom: 0;
      left: 0;
      position: absolute;
      content: '';
      display: block;
      height: 42px;
      background-color: #fff;
      width: 850px;
      z-index: 6;
    }

    //border: solid 1px;
       .donation {
        // .flex;
        // transition-timing-function: linear;
        //margin: 0 auto;


         .swiper-slide {
          .flex-center;
          width: 100%;
          height: 49px;
          margin-bottom: 0px !important;

           .donation-list-content {
            width: 752px;
            display: flex;
            align-items: center;
            height: 49px;
            //border: solid 1px;

             .content-dot {
              width: 7px;
              height: 7px;
              background: @dark-green;
            }

             .content-text {
              margin-left: 23px;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 1.2;
              width: 346px;
              .text-cut;
              padding-right: 5px;
              //border: solid 1px;
            }

             .content-money {
              width: 289px;
              height: 16px;
              font-size: 16px;
              font-weight: 400;
              line-height: 1.2;
              display: flex;
              align-items: center;
              //justify-content: center;
              //border: solid 1px red;

              > span {
                color: @green;
              }
            }

             .content-data {
              //margin-left: 181px;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 1.2;
            }


          }
        }

         .main-wrapper {
          width: 100%;
          height: 100%;
          padding: 10px;
        }

      }

    


  }


}


.index-partner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .partner-container {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 152px;

    > .partner-text-title {
      margin-top: 120px;
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 1.2;
      position: relative;

      > .partner-img {
        position: absolute;
        width: 49px;
        height: 47px;
        right: -65px;
        top: -44px;
      }


    }

    > .partner-text-english {
      margin-top: 11px;
      font-size: 16px;
      font-weight: 400;
      color: @gray;
      line-height: 1.2;
      margin-bottom: 47px;
    }

    > .partner-swiper {
      width: 1100px;
      //border: solid 1px red;

      > .swiper-wrapper {
        > .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          //width: 268px;
          height: 118px;
          border: 1px solid #EDEDED;
          //border: solid 1px red;
          padding: 5px;


          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            //border: solid  1px;
            > img {
              //width: 100%;
              //height: auto;
            }

          }
        }
      }
    }


    > .swiper-button-prev.partner-button-prev {
      display: flex;
      justify-content: center;
      align-items: center;
      //border: solid 1px red;
      background: #FFFFFF;
      color: @green;
      font-size: 35px;
      font-weight: bold;
      left: 0px;
      top: 55%;
    }

    > .swiper-button-next.partner-button-next {
      display: flex;
      justify-content: center;
      align-items: center;
      //border: solid 1px red;
      background: #FFFFFF;
      color: @green;
      font-size: 35px;
      right: 0px;
      top: 55%;
    }

  }


}


#footer {
  height: 100%;
  min-width: 1200px;
  //display: flex;
  //flex-direction: column;
  margin: 0;
  padding: 0;

  > .footer-01 {
    display: flex;
    background: @dark-green;
    align-items: center;

    > .footer-01-img {
      width: 50%;
      height: auto;

      > img {
        width: 100%;
      }
    }

    > .footer-text {
      width: 50%;
      display: flex;
      justify-content: center;
      height: auto;
      //border: solid 1px;
      padding-left: 10px;
      box-sizing: border-box;

      > .footer-text-content {
        display: flex;
        align-items: center;
        //border: solid 1px red;

        .footer-text-content-img {
          //border: solid 1px red;
          width: 266px;
          height: 266px;
          margin-right: 76px;
        }

        > .footer-text-content-text {
          //border: solid 1px red;
          width: 420px;
          height: 227px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > .footer-text-content-text-title {
            font-size: 28px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.2;
            text-shadow: 0 2px 3px rgba(21, 24, 2, 0.1);
          }

          > .footer-text-content-text-content {
            //border: solid 1px;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1.5;
            text-shadow: 0 2px 3px rgba(21, 24, 2, 0.1);
          }

          > a {
            display: flex;
            font-size: 18px;
            font-weight: 400;
            color: #869B06;
            line-height: 1.2;
            justify-content: center;
            align-items: center;
            width: 178px;
            height: 56px;
            background: #FFFFFF;
          }


        }
      }
    }
  }

  > .footer-02 {
    height: 260px;
    background-color: @green;
    padding-top: 45px;

    > .container {

      .container-var;

      > .footer-02-data {
        display: flex;
        align-items: center;

        > .footer-02-data-img-logo {
          //border: solid 1px;
          width: 146px;
          height: 144px;

        }

        > .footer-02-data-address {
          margin-top: -7px;
          margin-left: 102px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 110px;
          width: 390px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.2;
        }

        > .footer-02-data-icon {
          margin-left: 102px;
          display: flex;
          //width: 221px;
          align-items: center;
          margin-right: 105px;
          //border: solid 1px;
          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            margin-left: 31px;
            width: 34px;
            height: 28px;

            > img {
              max-width: 100%;
              max-height: 100%;
            }

            &:nth-child(1) {
              margin-left: 0;
              //border: solid 1px red;
            }
          }

        }

        > .footer-02-data-small-qr-code {

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          > .footer-02-data-img-logo {
            margin-top: 16px;
            width: 134px;
            height: 134px;
            margin-bottom: 12px;
          }

          > span {
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            //line-height: 1.2;
          }
        }

      }
    }
  }

  > .footer-03 {
    height: 59px;
    border-top: solid 1px #228A5D;
    background-color: @green;


    > .footer-03-container-02 {
      .container-var;


      > .footer-03-technical {
        display: flex;
        align-items: center;
        justify-content: space-between;


        > .footer-03-technical-copyright {
          width: 535px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: space-between;


          a:link, a:visited, a:active, a:hover {
            color: #FFFFFF;
          }

        }

        > .footer-03-technical-company {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 54px;

        }
      }
    }
  }

}

@media screen and  (max-width: 1800px) {
  #footer {
    > .footer-01 {
      > .footer-text {
        .footer-text-content {

          > .footer-text-content-img {
            margin-right: 66px;
          }

          > .footer-text-content-text {
            //margin-right: 10px;

            .footer-text-content-text-title {
              //font-size: 25px;
            }

            .footer-text-content-text-content {
              //font-size: 16px;
            }

            a {
              //font-size: 16px;

            }

          }
        }
      }
    }

  }


}

@media screen and  (max-width: 1700px) {
  #footer {
    > .footer-01 {
      > .footer-text {

        .footer-text-content {


          .footer-text-content-img {
            margin-right: 56px;
          }

          .footer-text-content-text {


            .footer-text-content-text-title {
              //font-size: 23px;
            }

            .footer-text-content-text-content {
              //font-size: 15px;
            }

            a {
              //font-size: 12px;
              //width: 100px;
              //height: 30px;
            }

          }
        }
      }
    }
  }

}

@media screen and  (max-width: 1600px) {
  //.index-partner {
  //  .partner-container {
  //    > .swiper-button-prev.partner-button-prev {
  //      //border: solid 1px red;
  //      left: 0;
  //      width: 27px;
  //      height: 34px;
  //      font-size: 32px;
  //      top: 56%;
  //    }
  //
  //    > .swiper-button-next.partner-button-next {
  //      //border: solid 1px red;
  //      right: 0;
  //      width: 27px;
  //      height: 34px;
  //      font-size: 32px;
  //      top: 56%;
  //    }
  //  }
  //}
  #footer {
    > .footer-01 {
      > .footer-text {
        .footer-text-content {
          .footer-text-content-img {
            height: 236px;
            width: 236px;
            margin-right: 46px;
          }

          .footer-text-content-text {
            height: 197px;

            .footer-text-content-text-title {
              font-size: 25px;
            }

            .footer-text-content-text-content {
              //flex-shrink: 0;
              font-size: 17px;
            }

            a {
              font-size: 15px;
              width: 144px;
              height: 36px;
            }

          }
        }
      }
    }

  }


}

@media screen and  (max-width: 1500px) {

  #footer {
    > .footer-01 {
      > .footer-text {
        .footer-text-content {

          .footer-text-content-img {
            height: 226px;
            width: 226px;
            margin-right: 36px;
          }

          .footer-text-content-text {
            height: 187px;

            .footer-text-content-text-title {
              font-size: 24px;
            }

            .footer-text-content-text-content {
              //flex-shrink: 0;
              font-size: 16px;
            }

            a {
              font-size: 15px;
              width: 144px;
              height: 36px;
            }

          }
        }
      }
    }

  }


}

@media screen and  (max-width: 1400px) {
  #footer {
    > .footer-01 {
      > .footer-text {
        .footer-text-content {
          .footer-text-content-img {
            height: 216px;
            width: 216px;
            margin-right: 26px;
          }

          .footer-text-content-text {
            height: 177px;
            width: 371px;

            .footer-text-content-text-title {
              font-size: 24px;
            }

            .footer-text-content-text-content {
              //flex-shrink: 0;
              font-size: 16px;
            }

            a {
              font-size: 15px;
              width: 144px;
              height: 36px;
            }

          }

        }
      }
    }

  }


}

@media screen and  (max-width: 1300px) {

  #footer {
    > .footer-01 {
      > .footer-text {
        .footer-text-content {
          .footer-text-content-img {
            height: 206px;
            width: 206px;
            margin-right: 16px;
          }

          .footer-text-content-text {
            height: 177px;
            width: 361px;


            .footer-text-content-text-title {
              font-size: 24px;
            }

            .footer-text-content-text-content {
              //flex-shrink: 0;
              font-size: 16px;
            }

            a {
              font-size: 15px;
              width: 144px;
              height: 36px;
            }

          }
        }
      }
    }

  }


}

//04 项目介绍

.page-project-introduce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .project-bg {
    width: 100%;
    position: relative;

    > .img {
      .img-wrap(100%, 500px);

      > img {
        min-width: 100%;
        min-height: 100%;
      }

    }


    > .introduce-title {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(26, 126, 83, 0.8);;

      > .title-text {
        padding-top: 80px;
        width: 432px;
        margin: 0 auto;
        font-size: 38px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 36px;
        //border: solid 1px red;
      }
    }

    > .introduce-swiper {
      position: absolute;
      max-width: 1400px;
      width: 73%;
      display: flex;
      padding: 4% 5%;
      background-color: #ffffff;
      left: 50%;
      transform: translate(-50%, 0%);
      top: 50%;
      z-index: 3;
      justify-content: space-between;

      > .introduce-swiper-content {
        width: 53%;
        margin: 0;

        > .swiper-wrapper .swiper-slide .swiper-item  {
          width: 100%;
          height: 100%;
          > img {
            min-width: 100%;
            min-height: 100%;
          }
        }

        > .swiper-button-prev {
          background: @dark-green;
          background-size: 0;
          color: #FFFFFF;
          width: 50px;
          height: 46px;
          left: 0;
          top: 95%;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > .swiper-button-next {
          background: @dark-green;
          background-size: 0;
          color: #FFFFFF;
          width: 50px;
          height: 46px;
          left: 50px;
          top: 95%;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
        }

      }

      > .swiper-item-text {
        // margin-left: 60px;
        // width: 500px;
        width: 43%;

        > .swiper-item-title {
          margin: 11% 0;
          font-size: 30px;
          font-weight: 400;
          color: @green;
          line-height: 1.8;
        }

        > .swiper-item-content {

          // height: 160px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 1.8;
          //border: solid 1px red;

        }
      }

    }

  }


  > .introduce-text {
    margin-top: 300px;
    font-size: 30px;
    font-weight: 400;
    color: #333333;
    line-height: 1;
    margin-bottom: 49px;
  }

  > .introduce-item {
    width: 1203px;
    display: flex;
    //border: solid 1px red;
    justify-content: space-between;
    margin-bottom: 151px;

    > .item-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -75px;
      margin-bottom: -70px;

      > li {
        margin-left: 75px;
        margin-bottom: 70px;
        width: 350px;

        &:nth-child(3n+1) {
          background: #64C2C8;
        }

        &:nth-child(3n+2) {
          background: @dark-green;
        }

        &:nth-child(3n+3) {
          background: #F7A941;
        }

        > .item-link {
          width: 100%;
          height: 100%;
          display: block;
          //border: solid 1px red;
          .img-scale;

          > .img {
            .img-wrap(100%, 228px);
          }

          > div {
            font-size: 22px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 350px;
            height: 92px;
            //border: solid 1px red;
          }

          > .item-text-button {
            .flex-center;
            padding: 0 10px;
            height: 92px;

            > div {
              .text-cut;
            }

          }

        }
      }

    }


  }


}


//04-01 项目详情
.page-project-details {
  background-color: #F7F7F7;
  padding-top: 51px;
  height: 100%;

  > .project-details-container {
    width: 1200px;
    margin: 0 auto;

    > .introduce-img {

      width: 1200px;
      height: 420px;
      //margin-top: 51px;
      margin-bottom: 33px;
      position: relative;

      .green-bg {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        width: 47%;
        height: 100%;
        background: url('@{image_url}detail.png') no-repeat center;
        background-size: 100% 100%;
      }

      > .img-content {
        position: absolute;
        top: 58px;
        //background: red;
        left: 41px;

        > .img-title {
          width: 410px;
          font-size: 30px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.5;
        }

        > .img-text {
          margin-top: 38px;
          width: 410px;
          height: 196px;
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.8;
        }
      }


    }

    > .details-show {
      display: flex;
      margin-bottom: 153px;


       .details-introduce {
        padding: 34px 30px 0 30px;
        width: 840px;
        background: #FFFFFF;

        > .introduce-title {
          font-size: 22px;
          font-weight: 400;
          color: #1A7E53;
          line-height: 1;
          height: 44px;
          display: flex;
          border-bottom: solid 1px #EDEDED;
          margin-bottom: 40px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 86px;
            height: 4px;
            bottom: 0;
            left: 0;
            background: #1A7E53;
          }
        }

        .article-content {
          padding-bottom: 0;
        }

      }

      > .sidebar {
        .item {
          margin-top: 40px;
          width: 330px;
        padding: 25px 24px 26px 24px;
        background: #FFFFFF;
        margin-left: 30px;
          &:nth-child(1) {
            margin-top: 0;
          }
        > .sidebar-title {
          display: flex;
          justify-content: space-between;
          height: 44px;
          border-bottom: solid 1px #EDEDED;
          margin-bottom: 23px;

          > .title-text {
            font-size: 22px;
            font-weight: 400;
            color: #1A7E53;
            line-height: 1;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              width: 86px;
              height: 4px;
              bottom: 0;
              background: #1A7E53;
              left: 0;
            }
          }

          > a {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 1;
          }
        }

        > .sidebar-list {


          > li {
            margin-top: 26px;

            &:nth-child(1) {
              margin-top: 0;
            }


            > a {
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 100%;

              > .img {
                //.img-wrap(120px, 78px);
                width: 120px;
                height: 78px;

                > img {
                  width: 120px;
                  height: 78px;
                }
              }

              > .list-date-title {
                display: flex;
                flex-direction: column;


                > .title {
                  font-size: 16px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 1.35;
                  .text-cut-2;
                  width: 140px;
                  height: 42px;
                  margin-top: 2px;
                }

                > .date {
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                  line-height: 1;
                  margin-top: 18px;
                }

              }
            }


          }

        }


        > .sidebar-btn {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 280px;
          height: 46px;
          background: #1A7E53;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1;

        }
        }
      }
    }
  }
}


//分页器
.no-data {
  margin-top: 81px;
  margin-bottom: 141px;
}

.pagination-box {
  margin-top: 81px;
  margin-bottom: 141px;

  > .page-number {
    border-radius: 0;
  }

  > .prev.pagination-btn {
    > span {
      > svg {
        transform: rotate(0.5turn);
      }
    }
  }

}

//04-02关联阅读
.page-project-read {


  > .read-container {
    width: 1200px;
    margin: 0 auto;

    > .widget-click-list {
      padding-top: 40px;
    }

    > .search-module {
      margin-top: 50px;
      margin-bottom: 42px;
      .flex-center;

      .search-box {
        .search-form {
          .flex-center;

          .form-wrap {
            width: 1200px;
            height: 46px;
            .flex-center;
            position: relative;

            input {
              color: #999999;
              line-height: 42px;
              font-size: 16px;
              width: 100%;
              height: 100%;
              border: 1px solid #dbdbdb;
              padding-left: 20px;
              border-radius: 0 4px 4px 0;
            }

            .cu-btn {
              top: 0;
              cursor: pointer;
              background-color: transparent;
              position: absolute;
              // right: -17px;
              right: 0;
              height: auto;
              padding: 0;

              .iconfont {
                .flex-center;
                width: 65px;
                height: 46px;
                background: #EDEDED;
                border-radius: 4px;

                > img {
                  width: 21px;
                  height: 21px;
                }
              }
            }
          }
        }
      }
    }

    .family-story {

      > .joinus-list {

        .joinus-item {
          margin-top: 39px;
          border: solid 1px #ededed;
          background: #FFFFFF;

          &:nth-child(1) {
            margin-top: 0;
          }

          a {
            .flex;
            width: 100%;
            height: 206px;

            .img-box {
              .img-wrap(320px, 206px);

              > img {
                transition: @transition;
                min-width: 100%;
                min-height: 100%;
              }
            }

            .content {
              padding: 36px 22px 32px 32px;
              width: calc(100% - 320px);

              h3 {
                font-size: 18px;
                color: @black;
                font-weight: bold;
              }

              .date-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 190px;
                margin: 21px 0 19px 0;


                > .date {
                  font-size: 14px;
                  color: @gray;
                }

                > .text {
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                  line-height: 1;
                  background: #F7F7F7;
                  width: 74px;
                  height: 32px;
                  border-radius: 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }


              }

              p {
                font-size: 14px;
                color: @light-black;
                line-height: 1.8;
              }

            }
          }

          &:hover {
            box-shadow: 0 2px 24px 0px rgba(0, 0, 0, 0.08);

            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }

  }

}

//05 杨爱活动

.page-activity {

  > .activity-content {
    margin-top: 83px;
    display: flex;
    justify-content: center;


    /*

      >li{
        margin-left: 75px;
        margin-bottom: 70px;
        width: 350px;

        > .item-link {
          width: 100%;
          height: 100%;
          display: block;
          //border: solid 1px ;
          .img-scale;
          > .img {
            .img-wrap(100%, 228px);
          }

          > div {
            font-size: 22px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 350px;
            height: 92px;
            //border: solid 1px red;
          }


    */

    > .activity-container {
      width: 1202px;
      //border: solid 1px;

      > .activity-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -75px;
        margin-bottom: -70px;

        > li {
          margin-left: 75px;
          margin-bottom: 70px;
          display: flex;
          flex-direction: column;
          box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
          width: 350px;


          &:nth-child(3n+1) {
            > a {
              > .img.support {
                > .img-support-text {
                  background: #64C2C8;
                }
              }
            }
          }

          &:nth-child(3n+2) {
            > a {
              > .img.support {
                > .img-support-text {
                  background: #AFCA0B;
                }
              }
            }
          }

          &:nth-child(3n+3) {
            > a {
              > .img.support {
                > .img-support-text {
                  background: #F7A941;
                }
              }
            }
          }

          > a {
            width: 100%;
            height: 100%;
            display: block;
            .img-scale;

            > .img {
              width: 100%;
              height: 228px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
            }

            > .img.support {
              position: relative;

              > .img-support-text {
                position: absolute;
                top: 0;
                left: 0;
                width: 84px;
                height: 34px;
                font-size: 14px;
                line-height: 1;
                .flex-center;
                color: #FFFFFF;
              }

            }

            > .activity-item-text {
              background: #FFFFFF;
              font-size: 18px;
              font-weight: 400;
              color: #333333;
              line-height: 1;
              height: 76px;
              .btn-text-centre;
              padding: 0 10px;

              > div {
                .text-cut;
              }
            }
          }
        }

        > .activity-item-end {
          margin-top: 70px;
        }
      }

    }


  }
}


//浮动的图标-------
.pagination-wrap {
  .flex-center;
  margin-top: 81px;
  margin-bottom: 141px;


  .page-nav {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid @border;
    .flex-center;

    &.disabled {
      opacity: .6;
      cursor: default;
    }
  }

  .page-prev {
    margin-right: 15px;
  }

  .page-next {
    margin-left: 15px;
  }

  .number {
    padding: 0 15px;
    color: @light-black;

    &:hover {
      color: @black;
    }

    &.on {
      color: #FFFFFF;
      background: #0c8c59;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & + .number {
      margin-left: 15px;
    }
  }

  .total-pages {
    font-size: 14px;
    color: @gray;
    margin-left: 25px;
  }
}


.icon-top-arrow-gray {
  .iconGen(icon-top-arrow-gray, 16px, 8px);
}

.icon-top-arrow-white {
  .iconGen(icon-top-arrow-white, 16px, 8px);
}


//移动图标
.floating-wrapper {
  position: absolute;
  right: 10px;
  top: 66%;
  z-index: 22;

  .float-list {

    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      background-color: #d7e485;
      opacity: 0.95;
      width: 52px;
      height: 50px;
      transition: all .3s;
      font-size: 14px;
      position: relative;

      .icon-wrapper {
        font-size: 0;


        .icon {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: inline-block;
          }
        }
      }

      .icon-svg {
        > .icon-xinaixin {
          color: #1a7e53;
          font-size: 26px;

        }

        > .icon-weixin {
          color: #1a7e53;
          font-size: 30px;
        }

        > .icon-arrow-up {
          color: #1a7e53;
        }
      }

      &.qr-xinaixin {
        border-radius: 27px 27px 0 0;
      }


      &:hover, &.active {
        //opacity: 1;
        background-color: #afca0b;

        .icon-svg {
          > .icon-xinaixin {
            color: #fff;
          }

          > .icon-weixin {
            color: #fff;
          }

          > .icon-arrow-up {
            color: #fff;
          }
        }
      }


      &.qr-code-box {

        &.ellipse {
          border-radius: 0 0 27px 27px;
        }


        &:hover {
          .qr-code {
            display: block;
          }
        }

        .qr-code {
          box-shadow: 0 2px 8px 0 rgba(2, 30, 7, 0.1);
          position: absolute;
          //right: 100%;
          top: 0;
          right: 59px;
          //padding: 20px;
          display: none;
          background-color: #FFFFFF;
          //border: solid 1px red;
          z-index: 66;

          .img {
            //.img-wrap(158px, 158px);
            //border: solid 1px;
            width: 158px;
            height: 158px;
            background-color: #FFFFFF;
          }


        }
      }


      &.to-top {
        border-radius: 0 0 27px 27px;

        > a {
          > .icon-svg {
            > .icon-arrow-up {
              transform: rotate(90deg);
              font-size: 22px;
            }
          }
        }


      }

    }
  }
}

.str_wrap {
  background-color: #fff !important;
}

.page-search-module {
  margin-top: 50px;
  margin-bottom: 42px;
  .flex-center;

  .search-box {
    .search-form {
      .flex-center;

      .form-wrap {
        width: 1200px;
        height: 46px;
        .flex-center;
        position: relative;

        input {
          color: #999999;
          line-height: 42px;
          font-size: 16px;
          width: 100%;
          height: 100%;
          border: 1px solid #dbdbdb;
          padding-left: 20px;
          border-radius: 0 4px 4px 0;
        }

        .cu-btn {
          top: 0;
          cursor: pointer;
          background-color: transparent;
          position: absolute;
          // right: -17px;
          right: 0;
          height: auto;
          padding: 0;

          .iconfont {
            .flex-center;
            width: 65px;
            height: 46px;
            background: #EDEDED;
            border-radius: 4px;

            > img {
              width: 21px;
              height: 21px;
            }
          }
        }
      }
    }
  }
}
