@import "./style";

.number-left-list(@mr, @number) {
    margin-top: -30px;
    width: 100%;
    .flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
        margin-top: 30px;
        width: calc((100% - @mr * (@number - 1)) / @number);
        margin-right: @mr;
        &:nth-child(@{number}n) {
            margin-right: 0;
        }
    }
}

.add-mb136 {
    margin-bottom: 136px;
}

.over-hidden {
    overflow: hidden;
}
// 定义不同的宽度
.big-width {
    width: 1400px;
    margin: 0 auto;
}
.middle-width {
    width: 1200px;
    margin: 0 auto;
}
.small-width {
    width: 1080px;
    margin: 0 auto;
}

.article-content {
    padding-bottom: 150px;
    // padding-top: 50px;
}

.mb80 {
    margin-bottom: 80px;
}

// 搜索页面
.page-search {
    // 关键词高亮
    .text-purple {
        color: @green;
    }
    .search-module {
        width: 100%;
        background-color: #F7F7F7;
        height: 199px;
        .flex-center;
        .search-box {
            .search-form {
                .flex-center;
                .form-wrap {
                  width: 600px;
                  height: 42px;
                  .flex-center;
                  position: relative;
                  input {
                      &::-ms-clear {
                          display: none;
                      }
                    color: #999999;
                    line-height: 42px;
                    font-size: 16px;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #dbdbdb;
                    border-radius: 21px;
                    padding-left: 20px;
                  }
                  .cu-btn {
                      right: 0;
                      top: 0;
                      height: 100%;
                    cursor: pointer;
                    background-color: transparent;
                    position: absolute;
                    right: 5px;
                    .iconfont {
                        .flex-center;
                      > img {
                          width: 23px;
                          height: 23px;
                      }
                    }
                  }
                }
              }
        }
    }
    .result-module {
        .number {
            margin-top: 55px;
            margin-bottom: 33px;
            >span {
                color: @green;
            }
        }
        ul {
            li {
                margin-top: 37px;
                border-bottom: 1px solid #f5f6f7;
                &:nth-child(1) {
                    margin-top: 0;
                }
                &:hover {
                    .title {
                        color: @green !important;
                    }
                }
                a {
                    .flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-bottom: 35px;
                    .content {
                        margin-bottom: 16px;
                        .title {
                            font-size: 20px;
                            color: @black;
                            margin-bottom: 16px;
                        }
                        .text {
                            font-size: 14px;
                            line-height: 1.8;
                            color: @light-black;

                        }
                    }
                    .date {
                        color: @gray;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

// aboutnav-widget 模块
.aboutnav {
    position: relative;
    // margin-bottom: 151px;
    .green-bg {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        width: 35%;
        height: 100%;
        background: url('@{image_url}nice.png') no-repeat center;
        background-size: 100% 100%;
    
    }
    .bg-box {
        width: 100%;
        .img-wrap(100%, 320px);
        > img {
            min-width: 100%;
            min-height: 320px;
        }
    }
    .aboutnav-title {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        .ch {
            font-size: 38px;
        }
        .eh {
            text-transform: Capitalize;
            font-size: 24px;
        }
    }
    
}
// aboutnavlist-widget
.aboutnav-nav {
    width: 100%;
    // position: absolute;
    // bottom: -69px;
    position: relative;
    margin-top: -41px;
    margin-bottom: 80px;
    .big-width {
        width: 100%;
        max-width: 1400px;
    }
    ul {
        height: 110px;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 2px 8px 0px 
    rgba(1, 27, 16, 0.06);
        .flex;
        justify-content: space-around;
        li {
            .flex-center;
            a {
                transition: @transition;
                position: relative;
                font-size: 20px;
                color: @black;
                padding-bottom: 20px;
                margin-top: 24px;
                border-bottom: solid 4px transparent;
            }
            &.active,
            &:hover {
                a {
                    color: @green;
                    border-bottom-color: @green;
                }
                
            }
        }
    }
}

.list-nav {
    > .middle-width {
        // margin-bottom: 136px;
        .flex;
        .left {
            margin-bottom: -46px;
            margin-right: 34px;
            // height: 228px;
            .flex;
            flex-direction: column;
            li {
                margin-bottom: 46px;
                // width: 100%;
                a {
                    display: block;
                    // width: 100%;
                    box-sizing: border-box;
                    padding-right: 31px;
                    max-width: 163px;
                    min-width: 105px;
                    font-size: 18px;
                    color: @light-black;
                }
                &:hover,
                &.active {
                    a {
                        .flex;
                        align-items: center;
                        position: relative;
                        color: @green;
                        &::after {
                            content: '';
                            display: block;
                            height: 3px;
                            width: 20px;
                            position: absolute;
                            background-color: @green;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 0px;
                        }
                    }
                }
            }
        }
    }
}

// 03机构介绍
.page-introduction {
    .title {
        text-align: center;
        font-size: 24px;
        color: @green;
        margin-bottom: 55px;
    }
}

// 03-01 组织架构
.page-council {
    .right {
        flex-basis: 100%;
        .flex-center;
        > .img-box {
            background-color: #f7f7f7;
            padding: 42px;
            .img-wrap(100%, auto);
            > img {
                width: 100%;
                height: auto;
            }
        }
    }
}

// 03-02 理事会
.page-council {
    .right {
        .include-page {
            width: 100%;
            .flex;
            flex-direction: column;
            justify-content: center;
        }
        .council-list {
            .flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            .council-item {
                width: 500px;
                background-color: #f7f7f7;
                margin-top: 40px;
                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0px;
                }
                .flex;
                justify-content: space-between;
                padding: 30px 28px;
                .img-box {
                    .img-wrap(150px, 150px);
                    border-radius: 50%;
                    > img {
                        min-height: 150px;
                        min-width: 150px;
                    }
                }
                .content {
                    width: 264px;
                    padding: 20px 0;
                    .title {
                        margin-bottom: 12px;
                        color: @green;
                        .flex;
                        align-items: baseline;
                        h3 {
                            font-size: 18px;
                            margin-right: 5px;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                    .text {
                        font-size: 14px;
                        line-height: 1.8;
                        color: @light-black;
                    }
                }
            }
        }
    }
}

// 03-03机构章程
.page-constitution {
    .content-top  {
        .flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
        .title {
            font-size: 24px;
            color: @green;
            margin-bottom: 27px;
        }
        .load {
            .flex;
            font-size: 16px;
            color: #36a9e1;
        
            .icon {
                margin-right: 7px;
            }
        }
    }
}

// 03-04 机构荣誉
.page-honor {
    .honor-list {
        width: 100%;
        .flex;
        flex-wrap: wrap;
        margin-top: -42px;
        .honor-item {
            width: 270px;
            margin-left: 40px;
            margin-top: 42px;
            border: 1px solid #EDEDED;
            &:nth-child(4n + 1) {
                margin-left: 0;
            }
            &:hover {
                border-color: transparent;
                box-shadow: 0px 2px 24px 0px
                rgba(0, 0, 0, 0.08);
                cursor: pointer;
                .img-box {
                    > img {
                        transform: scale(1.1)
                    }
                }
            }
            .img-box {
                .img-wrap(270px, 176px);
                > img {
                    transition: .3s;
                    min-width: 100%;
                    min-height: 100%;
                }
            }
            > p {
                margin: 18px 13px;
                font-size: 16px;
                color: @black;
                line-height: 1.8;
            }
        }
    }
    // 弹窗
    .honor-popup-css {
        padding: 0;
        background-color: transparent;
        width: 822px;
        min-height: unset !important;
        .icon-close {
            position: absolute;
            right: 39px;
            top: 0px;
        }
        .popup-content {
            position: relative;
            margin-top: 58px;
            max-height: 600px;
            .swiper-container {
                width: 720px;
                .swiper-wrapper {
                    .swiper-slide {
                        .img-box {
                            .img-wrap(720px, auto);
                            > img {
                                width: 100%;
                                height: auto;
                                min-height: 100%;
                                min-width: 100%;
                            }
                        }
                        p {
                            color: #fff;
                            font-size: 26px;
                            text-align: center;
                            margin-top: 30px;
                        }
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                top: 235px;
                width: 20px;
                height: 41px;
                background: url('@{icon_url}toright.png') no-repeat center;
                background-size: 20px 41px;
                right: 0px;
                left: auto;
            }
            .swiper-button-prev {
                transform: rotateZ(180deg);
                left: 0px;
                right: auto;
            }
        }
    }
}

// 03-06 联系我们
.page-connect {
    // 取消百度云的logo
    .BMap_cpyCtrl {
        display: none;
    }
        
    .anchorBL {
        display: none;    
    }
    .map { 
        border: solid 1px #ededed;
        #mapBox {
            width: 100%;
            height: 526px;
        } 
    }
    .content {
        margin-top: 56px;
        margin-bottom: 160px;
        .flex;
        justify-content: space-between;
        align-items: flex-end;
        .left {
            margin-right: 45px;
            h3 {
                font-size: 24px;
                font-weight: bold;
                color: @black;
                
            }
            ul {
                li {
                    margin-left: 3em;
                    text-indent: -3em;
                    margin-top: 24px;
                    font-size: 18px;
                    color: @black;
                }
            }
        }
        .right {
            .img-box {
                box-sizing: border-box;
                width: 186px;
                height: 186px;
                padding: 10px;
                .flex-center;
                border: solid 1px #ededed;
                > img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

// 03-07 加入我们 06新闻动态 07家庭故事
.page-joinus,
.page-news,
.page-story {
    .joinus-list {
        .joinus-item {
            margin-top: 39px;
            border: solid 1px #ededed;
            &:nth-child(1) {
                margin-top: 0;
            }
            a {
                .flex;
                width: 100%;
                height: 206px;
                .img-box {
                    .img-wrap(320px, 206px);
                    > img {
                        transition: @transition;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }
                .content {
                    padding: 36px 22px 32px 32px;
                    width: calc(100% - 320px);
                    h3 {
                        font-size: 18px;
                        color: @black;
                        font-weight: bold;
                    }
                    .date {
                        font-size: 14px;
                        color: @gray;
                        margin: 22px 0;
                    }
                    p {
                        font-size: 14px;
                        color: @light-black;
                        line-height: 1.8;
                    }
                    
                }
            }
            &:hover{
                border-color: transparent;
                box-shadow: 0px 2px 24px 0px 
		        rgba(0, 0, 0, 0.08);
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

// 06-01 详情 07-01详情 08-01详情
.page-new-detail,
.page-story-detail,
.page-resource-detail,
.page-join-detail {
    .top {
        margin-bottom: 50px;
        background-color: #f7f7f7;
        height: 259px;
        .all {
            .flex;
            height: 100%;
            flex-direction: column;
            .title {
                flex-grow: 1;
                .flex-center;
                flex-direction: column;
                h3 {
                    color: @black;
                    font-size: 30px;
                    font-weight: bold;
                    margin-bottom: 26px;
                }
                .date {
                    color: @gray;
                    font-size: 18px;
                }
            }
        }
        
    }
}

// widget-click
.widget-click-list {
    .flex;
    padding-top: 37px;
    .widget-click-item {
        .flex;
        color: @gray;
        font-size: 16px;
        a {
            color: @gray;
            font-size: 16px;
            .flex;
            align-items: center;
        }
        &:not(:nth-child(1)):before {
            content: '>';
            padding: 0 5px;
            display: block;
        }
    }
} 

// 08资源库
.page-resource {
    .resource-list {
        .flex;
        flex-wrap: wrap;
        margin-left: -60px;
        margin-top: -24px;
        .resource-item {
            width: 570px;
            margin-top: 24px;
            margin-left: 60px;
            border-bottom: 1px solid #ededed;
            a {
                .flex;
                justify-content: space-between;
                padding-bottom: 20px;
                .file-msg {
                    .flex;
                    .file-icon {
                        .flex-center;
                        margin-right: 14px;
                    }
                    .filename {
                        margin-right: 20px;
                        .flex-center;
                        font-size: 18px;
                        color: @black;
                    }
                }
                .date {
                    flex-shrink: 0;
                    .flex-center;
                    font-size: 16px;
                    color: @gray;
                }
            }
        }
    }
}

// 08-01 详情
.page-resource-detail {
    .top {
        .date-nav {
            .flex;
            .detailed-list {
                margin-left: 50px;
                padding: 0 7px;
                background-color: #fff;
                border: solid 1px #1b9363;
                color: #1b9364;
                font-size: 14px;
                .flex-center;
            }
        }
    }
    .content {
        .file-loader {
            margin-top: -11px;
            width: 100%;
            .flex-center;
            padding-bottom: 34px;
            border-bottom: 1px solid #f4f4f4;
            margin-bottom: 42px;
            span {
                font-size: 18px;
                color: @black;
                .flex-center;
            }
            a {
                .flex;
                .file-icon {
                    margin-right: 10px;
                }
                .filename {
                    font-size: 18px;
                    color: #0c8c59;
                    .flex-center;
                }
            }
        }
    }
}

// 09信息公开
.page-msg {
    .msg-list {
        .flex;
        flex-wrap: wrap;
        margin-left: -55px;
        margin-top: -46px;
        .msg-item {
            width: 195px;
            margin-left: 55px;
            margin-top: 46px;
            a {
                .img-box {
                    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
                    .img-wrap(196px, 260px);
                    > img {
                        transition: @transition;
                        min-height: 260px;
                        min-width: 196px;
                    }
                }
                p {
                    margin-top: 14px;
                    font-size: 16px;
                    color: @black;
                }
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }

        }
    }
}

// 09-01捐赠公示
.page-msg-detail {
    .search {
        .search-form {
            margin-bottom: 28px;
            .form-wrap {
              width: 327px;
              height: 46px;
              .flex-center;
              position: relative;
              input {
                color: #999999;
                .flex-center;
                font-size: 14px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                border-radius: 4px;
	            border: solid 1px #ededed;
                padding-left: 10px;
              }
              .cu-btn {
                  top: 0;
                width: 65px;
                height: 46px;
                cursor: pointer;
                position: absolute;
                right: 0;
                
              }
            }
          }
    }
    .table {
        border: solid 1px #ededed;
        width: 100%;
        .flex;
        flex-direction: column;
        .head {
            padding: 0 30px;
            width: 100%;
            height: 54px;
            .flex;
            // justify-content: space-between;
            align-items: center;
            background-color: #f7f7f7;
            color: #1a7e53;
            font-size: 18px;
            .th {
                padding-right: 6px;
                flex-basis: 45%;
                flex-shrink: 0;
                flex-grow: 0;
                text-align: left;
                &:nth-child(3) {
                    flex-basis: 10%;
                }
            }
            
        }
        .tbody {
            padding: 6px 30px 3px 30px;
           .tr {
                margin-top: 22px; 
                margin-bottom: 22px;
                .flex;
                justify-content: space-between;
                .td {
                    box-sizing: border-box;
                    padding-right: 6px;
                    flex-wrap: wrap;
                    flex-basis: 45%;
                    flex-shrink: 0;
                    flex-grow: 0;
                    text-align: left;
                    &:nth-child(3) {
                        flex-basis: 10%;
                    }
                    > span {
                        color: @green;
                    }
                }
            }
        }
    }
}

// 左边的关联报道，关联活动
.left-sidebar {
    .item {
        margin-top: 40px;
        width: 330px;
        padding: 25px 24px 26px 24px;
        background: #FFFFFF;
        margin-left: 30px;
        &:nth-child(1) {
            margin-top: 0;
        }
        > .sidebar-title {
        display: flex;
        justify-content: space-between;
        height: 44px;
        border-bottom: solid 1px #EDEDED;
        margin-bottom: 23px;

        > .title-text {
            font-size: 22px;
            font-weight: 400;
            color: #1A7E53;
            line-height: 1;
            position: relative;

            &:after {
            content: '';
            position: absolute;
            width: 86px;
            height: 4px;
            bottom: 0;
            background: #1A7E53;
            left: 0;
            }
        }

        > a {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 1;
        }
    }

    > .sidebar-list {


      > li {
        margin-top: 26px;

        &:nth-child(1) {
          margin-top: 0;
        }


        > a {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;

          > .img {
            //.img-wrap(120px, 78px);
            width: 120px;
            height: 78px;

            > img {
              width: 120px;
              height: 78px;
            }
          }

          > .list-date-title {
            display: flex;
            flex-direction: column;


            > .title {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 1.35;
              .text-cut-2;
              width: 140px;
              height: 42px;
              margin-top: 2px;
            }

            > .date {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              line-height: 1;
              margin-top: 18px;
            }

          }
        }


      }

    }


    > .sidebar-btn {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 280px;
      height: 46px;
      background: #1A7E53;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1;

    }
    }
}
