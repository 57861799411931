//url
@image_url: '../../../../img/pc/';
@icon_url: "@{image_url}icon/";






.container-var() {
  width: 1200px;
  margin: 0 auto;
}


.text-centre() {
  margin: 0 auto;
  width: 170px;
  height: 100px;
  display: flex;
  flex-direction: column;
}

.text-centre-log() {
  margin-left: 121px;
  > img {
    width: 49px;
    height: 47px;
  }
}


.text-centre-text-01() {
  margin-right: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text-centre-text-title() {
  font-size: 26px;
  font-weight: 400;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: 0;
  color: #333333;
}

.text-centre-text-english() {
  font-weight: 400;
  font-size: 16px;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0;
  color: #999999;
}

.text-cut() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.swiper-item-content() {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;
  height: 69px;
  border-bottom: solid 1px #EDEDED;

  > div {
    display: flex;

    > .swiper-item-icon {
      width: 20px;
      height: 23px;
      margin-right: 13px;
    }

    > .swiper-item-text {
      white-space: nowrap;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 1.2;
      .text-cut;
      width: 250px;
    }
  }

  > .swiper-item-date {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 1.2;
  }
}

.btn-text-centre(){
  display: flex;
  justify-content: center;
  align-items: center;
}